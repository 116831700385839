import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  params: any = {
    email: '',
    password: '',
    admin: 1,
  };
  error: string = '';
  loading = false;

  constructor(
    private api: ApiService,
    private user: UserService,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  submit() {
    this.error = '';

    if (this.params.email === '' || this.params.password === '') {
      return;
    }

    this.startLoading();

    this.api.call('auth/login', null, 'post', this.params).subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          this.user.set(response.data);
          this.permissions();
          return;
        } else {
          this.error = response.message.join('<br/>');
        }

        this.stopLoading();
      },
      error: (error: any) => {
        this.error = error;
        this.stopLoading();
      },
    });
  }

  permissions() {
    this.api.call('admin/catalogs/access').subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          this.user.permissions(response.data);
          this.router.navigate([environment.dashboard], { replaceUrl: true });

          return;
        } else {
          this.error = response.message.join('<br/>');
        }
      },
      error: (error: any) => {
        this.error = error;
      },
    });
  }

  startLoading() {
    this.loadingService.start();
    this.loading = true;
  }

  stopLoading() {
    this.loadingService.stop();
    this.loading = false;
  }
}
